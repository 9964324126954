import firebase from 'firebase/app';
import { Shop } from '../models/shop';
import { UserProfile } from '../models/user';

const getShops = async (): Promise<Shop[]> => {
  const snapshot = await firebase.firestore()
  .collection('Shops')
  .orderBy('name')
  .orderBy('id')
  .get();
  return snapshot.docs.map((docs) => ({
    id: parseInt(docs.id),
    name: docs.get('name'),
    zipCode: docs.get('zipCode'),
  }));
};


const getShopUserProfiles = async (shopId: number): Promise<UserProfile[]> => {  
  const snapshot = await firebase
  .firestore()
  .collection('UserProfiles')
  .where("barberShop", "==", shopId)
  .orderBy("firstName")
  .orderBy("lastName")
  .get();
  
  return snapshot.docs.map((docs) => ({
    id: docs.get('id'),
    barberShop: docs.get('barberShop'),
    celPhone: docs.get('cellPhone'),
    createdAt: docs.get('createdAt'),
    email: docs.get('email'),
    firstName: docs.get('firstName'),
    lastName: docs.get('lastName'),
    userType: docs.get('userType'),
    zipCode: docs.get('zipCode'),
  }));
};

const shopService = {
  getShops,
  getShopUserProfiles,
};

export default shopService;
