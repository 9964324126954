import {
  IonBadge,
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonNote,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { partition, find } from 'lodash';
import React from 'react';
import { Shop } from '../models/shop';
import { UserProfile } from '../models/user';
import { UserTypes } from '../models/enum/userType.enum';
import shopService from '../services/shopService';
import './Users.css';



const renderShops = (
  shops: Shop[],
  clickHandlerWithShop: (profile: Shop) => () => void) => {
  if (!shops) return null;

  return shops.map(shop => {
    const { id, name } = shop;
    return (
      <IonItem key={id} onClick={clickHandlerWithShop(shop)}>
        <IonLabel>{`${name}`}</IonLabel>
      </IonItem>
    );
  });
};

const renderUsers = (userProfiles: UserProfile[]) => {
  if (!userProfiles) return null;

  return userProfiles.map(profile => {
    const { id, firstName, lastName, userType } = profile;
    return (
      <IonItem key={id} >
        <IonNote slot="end">
          <IonBadge color="primary" className="badge">
            {userType === UserTypes.HP ? 'HP' : 'C'}
          </IonBadge>
        </IonNote>
        <IonLabel>{firstName} {lastName} </IonLabel>
      </IonItem>
    );
  });
};

const Shops: React.FC = () => {
  const [shops, setShops] = React.useState<Shop[]>([]);
  const [loadingShops, setLoadingShops] = React.useState(false);
  const [selectedShop, setSelectedShop] = React.useState<Shop>();
  const [userProfiles, setUserProfiles] = React.useState<UserProfile[][]>([]);

  const [showModal, setShowModal] = React.useState(false);

  const getShops = React.useCallback(async () => {
    const shops = await shopService.getShops();
    setShops(shops);
    setLoadingShops(false);
  }, []);

  const getHPName = (barberId: string) => {
    return find(userProfiles[0], ['id', barberId]);
  }

  const handleModalDismiss = () => {
    setShowModal(false);
  };

  const clickHandlerWithShop = (shop: Shop) => async () => {
    setSelectedShop(shop);
    setShowModal(true);

    const userProfiles = await shopService.getShopUserProfiles(shop.id);
    setUserProfiles(partitionUsers(userProfiles));
  };

  const partitionUsers = (profiles: UserProfile[]) => {
    const partitionedUsers = partition(profiles, { userType: UserTypes.HP });
    return partitionedUsers;
  };

  React.useEffect(() => {
    setLoadingShops(true);
    getShops();
  }, [getShops]);

  if (loadingShops) {
    return (
      <IonPage>
        <IonLoading isOpen={true} />
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Shops</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Shops</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {renderShops(shops, clickHandlerWithShop)}
        </IonList>
        <IonModal isOpen={showModal} cssClass="">
          <IonPage>
            <IonContent class="content">
              <IonText>
                <h1>{selectedShop?.name}</h1>
              </IonText>
              <IonList>
                <IonTitle>Hair Professionals</IonTitle>
                {renderUsers(userProfiles[0])}
              </IonList>
              <IonList>
                <IonTitle>Clients</IonTitle>
                {renderUsers(userProfiles[1])}
              </IonList>
              <IonButton expand="block" color="primary" onClick={handleModalDismiss}>
                Close</IonButton>
            </IonContent>
          </IonPage>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Shops;
